var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    _vm._g(
      { staticClass: "grid-view-item mb-base overflow-hidden" },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "no-body" },
        [
          _c(
            "div",
            {
              staticClass:
                "item-img-container bg-white h-64 flex items-center justify-center mb-4 cursor-pointer",
              on: { click: _vm.navigate_to_detail_view },
            },
            [
              _c("img", {
                staticClass: "grid-view-img px-4",
                attrs: { src: _vm.item.image, alt: _vm.item.name },
              }),
            ]
          ),
          _c("div", { staticClass: "item-details px-4" }, [
            _c("div", { staticClass: "flex justify-between items-center" }, [
              _c(
                "div",
                {
                  staticClass:
                    "text-warning border border-solid border-warning flex py-1 px-2 rounded",
                },
                [
                  _c("span", { staticClass: "text-sm mr-1" }, [
                    _vm._v(_vm._s(_vm.item.rating)),
                  ]),
                  _c("feather-icon", {
                    attrs: { icon: "StarIcon", svgClasses: "h-4 w-4" },
                  }),
                ],
                1
              ),
              _c("h6", { staticClass: "font-bold" }, [
                _vm._v("$" + _vm._s(_vm.item.price)),
              ]),
            ]),
            _c("div", { staticClass: "my-4" }, [
              _c(
                "h6",
                {
                  staticClass:
                    "truncate font-semibold mb-1 hover:text-primary cursor-pointer",
                  on: { click: _vm.navigate_to_detail_view },
                },
                [_vm._v(_vm._s(_vm.item.name))]
              ),
              _c("p", { staticClass: "item-description truncate text-sm" }, [
                _vm._v(_vm._s(_vm.item.description)),
              ]),
            ]),
          ]),
          _vm._t("action-buttons"),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }